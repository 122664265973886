import { graphql, useStaticQuery } from "gatsby"

import Layout from '../layouts'
import PageHeader from '../components/page/header'
import React from 'react'
import Seo from '../components/seo'
import Text from '../components/text'

const Therapy = () => {
  const { pageHeaderImage } = useStaticQuery(
    graphql`
      {
        pageHeaderImage: imageSharp(resize: {originalName: {eq: "contact.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1000
          )
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title='Kontakt'
        currentUrl='/kontakt'
      />
      <PageHeader
        headline='Kontakt'
        copy='Wenn ich Ihr Interesse geweckt habe, würde ich mich freuen, Sie bald in meiner Sport- und Therapiepraxis begrüßen zu dürfen.'
        image={pageHeaderImage}
      />
      <Text text={`
__Unleashed Therapy__
David Witton

Grube Königsberg
35444 Biebertal

[david@unleashed-therapy.com](david@unleashed-therapy.com)
+49 162 3979797
      `}/>
      <Text text={`
## Sport- und Therapiepraxis
Der 90 m2 große Trainings- und Therapieraum verbindet harmonisch Natur mit Moderne und liegt in der neu erschaffenen Grube Königsberg. Eine ruhige Lage mit Zugang zum Wald, Wander- und Radwegen, welche sowohl für das Training und Coaching genutzt werden können, als auch zum eigenständigen Entspannen in der Natur einladen. Vor der Räumlichkeit sind ausreichend Parkmöglichkeiten vorhanden. Nach ca. 14 km erreichen Sie sowohl die Innenstadt von Gießen als auch von Wetzlar.
      `}/>
      <Text text={`
## Hausbesuche
Personaltraining, Coaching und Therapie bei Ihnen zu Hause. Sie haben keine Anfahrt und volle Privatsphäre. Jegliches Trainingsmaterial sowie Therapiebedarf, inklusive einer Therapieliege bringe ich mit.
      `}/>
    </Layout>
  )
}

export default Therapy
